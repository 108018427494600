html {
  height: 100%;
}

.mui-fixed {
  position: fixed;
}

body > #root {
  height: 100%;
  /* display: flex;
  flex-direction: column; */
}

body {
  white-space: pre-line;
  height: 100%;
  margin: 0;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'Montserrat','Rubik', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* -webkit-overflow-scrolling: touch;
  -webkit-perspective: 1px;
  -moz-perspective: 1px;
  perspective: 1px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
